import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Logo } from "../Icons";
import NavBar from "../NavBar";

export function NOHR() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center text-center">
        <div>
          <Logo />
          <h4 className="display-5">Service Not Subscribed</h4>{" "}
          <Button
            onClick={() => navigate(-1)}
            variant=""
            className="text-primary text-underline"
          >
            Go back
          </Button>
        </div>
      </div>
    </>
  );
}
