import CloseIcon from "mdi-react/CloseIcon";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/sidebar.scss";
import eventBus from "../utils/EventBus";
import { styleHelper } from "../utils/helpers";
import { useLocalStorage, useOnClickOutside } from "../utils/hooks";
import { GearIcon, SideBarCollapseIcon } from "./Icons";
import { QuickLookUpsModal } from "./modals/QuickLookUps";
import { sideBarItems } from "../utils/sideBarItems";
import { HRFrontEndUrl, IS_HR } from "../config";

export default function SideBar() {
  const [items, setItems] = useState([...sideBarItems]);

  const sideBar = useRef();
  const [isOpen, setIsOpen] = useLocalStorage("isSidebarOpen", true);
  const [isOpenMobile, setIsOpenMobile] = useLocalStorage(
    "isSidebarOpenMobile",
    false
  );

  const [lookUps] = useLocalStorage("lookUps", null);

  eventBus.useCustomEventListener("setIsOpenMobile", setIsOpenMobile);
  useOnClickOutside(sideBar, () => setIsOpenMobile(false));

  const [QuickLookUpsModalShow, setQuickLookUpsModalShow] = useState(false);

  useEffect(() => {
    // 👇️ run function when component unmounts 👇️
    return () => {
      setIsOpenMobile(false);
    };
  }, []);

  // get the sidebar item from localstorage when the component loads
  // useEffect(() => {
  //   // 👇️ run function when component unmounts 👇️
  //   const all = [];
  //   lookUps.forEach((e) => {
  //     sideBarItems.forEach((d) => {
  //       if (e === d.name) {
  //         all.push(d);
  //       }
  //     });
  //   });

  //   setItems([...all]);
  // }, [lookUps]);

  // TODO - quick look up settings will be here

  // Function to get the selected sidebar
  const getSelectedSideBar = (sideBar) => {
    // Update the selected sidebars
    // const all = [];
    // sideBar.forEach((e) => {
    //   sideBarItems.forEach((d) => {
    //     if (e === d.name) {
    //       all.push(d);
    //     }
    //   });
    // });
    // setItems([...all]);
  };

  return (
    <div
      className={`side-bar-holder ${
        !isOpen && !styleHelper.isMobile ? "collapse-side-bar" : ""
      } ${!isOpenMobile ? "closed-mobile" : "opened-mobile"}`}
    >
      <div
        ref={(el) => (sideBar.current = el)}
        className="side-bar bg-primary p-3 py-4 p-md-4"
      >
        <button
          onClick={() => setIsOpenMobile(false)}
          className="btn p-1 close"
        >
          <CloseIcon />
        </button>
        <button
          className="btn d-flex w-100 text-white look-ups"
          // onClick={() => setQuickLookUpsModalShow(true)}
        >
          Quick Lookups: <GearIcon />
        </button>

        <ul>
          {items.map((item, index) => (
            <li key={index}>
              {item.to === "hr" ? (
                IS_HR ? (
                  <a
                    className="btn"
                    title={item.name}
                    href={`${HRFrontEndUrl}/login`}
                  >
                    <div className="icon">{item.icon}</div>
                    <span>{item.name}</span>
                  </a>
                ) : (
                  <Link to="/no-hr" className="btn" title={item.name}>
                    <div className="icon">{item.icon}</div>
                    <span>{item.name}</span>
                  </Link>
                )
              ) : (
                <Link to={item?.to || ""} className="btn" title={item.name}>
                  <div className="icon">{item.icon}</div>
                  <span>{item.name}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>

        <button onClick={() => setIsOpen(!isOpen)} className="btn collapse-btn">
          <SideBarCollapseIcon />
        </button>
      </div>
      <QuickLookUpsModal
        show={QuickLookUpsModalShow}
        onHide={() => setQuickLookUpsModalShow(false)}
        getselectedsidebar={getSelectedSideBar}
      />
    </div>
  );
}
