import currency from "currency.js";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import "./../../assets/scss/scoped/number-input.scoped.scss";

const NumberCustomInput = React.forwardRef(
  (
    { className, name, value, onValueChange, isInvalid, onBlur, ...props },
    ref
  ) => {
    const increment = (number) => {
      return Number(
        currency(number, {
          symbol: "",
          separator: "",
        })
          .add(1)
          .format()
      );
    };

    const decrement = (number) => {
      return Number(
        currency(number, {
          symbol: "",
          separator: "",
        })
          .subtract(1)
          .format()
      );
    };

    return (
      <div
        className={`number-input ${
          isInvalid ? "border-danger shadow-none" : ""
        } ${props?.disabled ? "bg-lighter" : ""}`}
      >
        <button
          onClick={() => {
            value && onValueChange(Number(decrement(value)), name);
          }}
          className="btn px-0"
          type="button"
          disabled={props?.disabled}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM5 9H15V11H5V9Z"
              fill="#2463AE"
            />
          </svg>
        </button>
        <CurrencyInput
          {...props}
          name={name}
          value={value}
          onValueChange={onValueChange}
          onBlur={onBlur}
          onClick={(e) => e.target.select()}
          ref={ref}
        />
        <button
          onClick={() =>
            Number(value)
              ? onValueChange(Number(increment(value)), name)
              : onValueChange(Number(increment(1)), name)
          }
          className="btn px-0"
          type="button"
          disabled={props?.disabled}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z"
              fill="#2463AE"
            />
          </svg>
        </button>
      </div>
    );
  }
);

export default NumberCustomInput;
