import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
  ExcelIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";

function InventoryAnalysis() {
  useScrollTop();
  const initialFilterParams = {
    itemId: "",
    itemName: "",
  };

  const { backendUrl } = useAuth();

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchAnalysis = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/inventory-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Branch,
        value: el.Branch,
      })),
    ];

    return data;
  };

  const {
    data = {
      count: 0,
      inventory: [],
    },
    refetch,
    isSuccess,
    isFetching,
  } = useQuery(
    [reportActions.INVENTORY_ANALYSIS, queryParams],
    () => fetchAnalysis(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/inventory-analysis?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = exData.data.inventory.map((d, i) => [
      d.Bar_Code,
      d.Item_Name,
      currency(d.Received, {
        symbol: "",
        precision: 1,
      }).format(),
      currency(d.Sold, {
        symbol: "",
        precision: 1,
      }).format(),
      currency(d.Balance, {
        symbol: "",
        precision: 1,
      }).format(),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Inventry Analysis Report"],
      [date],
      [""],
      [
        "item ID",
        "Description",
        "QTY Received",
        "QTY Sold",
        "Quantity In Stock",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Recieved",
        currency(data.received, {
          symbol: "",
          precision: 1,
        }).format(),
      ],
      [
        "Total Sold",
        currency(data.sold, {
          symbol: "",
          precision: 1,
        }).format(),
      ],
      [
        "Total In Stock",
        currency(data.balance, {
          symbol: "",
          precision: 1,
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      itemId: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      name="branch"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.branch,
                        data?.branch,
                        {
                          value: "",
                          label: "All",
                        }
                      )}
                      options={data?.branch || []}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Item ID</Form.Label>

                    <InputGroup>
                      <Form.Control
                        name="itemId"
                        placeholder="Enter Item ID"
                        value={filterParams?.itemId}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />

                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <ItemsTable handleSelectedItem={handleSelectedItem} />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Item Name</Form.Label>
                    <Form.Control
                      name="itemName"
                      value={filterParams?.itemName}
                      placeholder="Enter Item name"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <div className="">
                    <Form.Check
                      inline
                      label="Show for all items"
                      name="showForAllItems"
                      type={"checkbox"}
                      id={`showForAllItems`}
                      defaultChecked={JSON.parse(
                        filterParams?.showForAllItems || true
                      )}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div>

                  <hr className="mt-1 mb-4" />

                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Enable Date Range"
                      name="enableDateRange"
                      type={"checkbox"}
                      id={`enableDateRange`}
                      checked={filterParams?.enableDateRange}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div>

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Inventory Analysis
                <button onClick={() => refetch()} className="btn text-primary">
                  <CachedIcon />
                </button>
              </h1>
              <div className="actions mr-5">
                {/*  <form action="align-items-center">
              <MagnifyIcon className="searchIcon" />
              <input
                type="text"
                name="search"
                placeholder="Search item ID or item name..."
              />
            </form> */}

                {/*   <button className="btn print">
                  Print <PrintIcon />
                </button> */}
                <CSVLink
                  className="btn print d-none"
                  filename={`Inventry Analysis(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/reports/pdf/inventory-analysis?${queryString.stringify(
                          rest
                        )}`}
                        target="blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtnWhite btn-outline-primary "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th>item ID</th>
                    <th>Description</th>
                    <th>QTY Received</th>
                    <th>QTY Sold</th>
                    <th>Quantity In Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {data.inventory.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Bar_Code}</td>
                      <td>{el.Item_Name}</td>
                      <td>
                        {currency(el.Received, {
                          symbol: "",
                          precision: 1,
                        }).format()}
                      </td>
                      <td>
                        {currency(el.Sold, {
                          symbol: "",
                          precision: 1,
                        }).format()}
                      </td>
                      <td>
                        {currency(el.Balance, {
                          symbol: "",
                          precision: 1,
                        }).format()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {!isFetching && isSuccess && isEmpty(data?.inventory) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            {/* <div className="p-4 border-top">
            <h2>Summary</h2>
            <div className="summary">
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Cash Sales</td>
                    <td>5,800.00</td>
                </tr>

                <tr>
                    <td>Total Cash PMT</td>
                    <td>350,250,895.00</td>
                </tr>

                <tr>
                    <td>Total Credit/Debit Card/Cheque</td>
                    <td>1,204,449,629.06</td>
                </tr>

                <tr>
                    <td>Total Cash</td>
                    <td>350,256,695.00</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Credit Sales</td>
                    <td>1,688,912,055.73</td>
                </tr>

                <tr>
                    <td>Total Posted</td>
                    <td>3,243,668,379.79</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Expenses</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>POS to Bank</td>
                    <td>103,366,800.00</td>
                </tr>

                <tr>
                    <td>Direct Bank Transfer</td>
                    <td>1,091,328,596.19</td>
                </tr>

                <tr>
                    <td>Cash Sent to Bank</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>Cash at Hand</td>
                    <td>337,732,030.10</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div> */}

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.received, {
                        symbol: "",
                        precision: 1,
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Recieved</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.sold, {
                        symbol: "",
                        precision: 1,
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Sold</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrown">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.balance, {
                        symbol: "",
                        precision: 1,
                      }).format()}
                    </p>
                    <p className="gridChld2">Total In Stock</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLoader show={isfetchingExcel} />
    </main>
  );
}

export default InventoryAnalysis;
