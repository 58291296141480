import { Dropdown, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Link } from "react-router-dom";
import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import Select from "react-select";
import CachedIcon from "mdi-react/CachedIcon";
import SalesByInvoice from "../Reports/SalesByInvoice";

export function InvoiceList() {
  /* useScrollTop();
  const initialFilterParams = { q: "" };

  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const data = {}; */

  return (
    /*  <main className="bg-white p-3">
      <header>
        <div className="search-area d-flex justify-content-between gap-3 w-100">
          <div className="d-flex gap-3">
            <Dropdown className="default-dropdown" style={{ margin: 0 }}>
              <Dropdown.Toggle variant="primary" bsPrefix="py-2 h-100 me-1">
                + Create Invoice
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className=""
              >
                <Dropdown.Item as="div">
                  <Link to="/sales-and-invoicing/create-invoice">
                    Standard Invoice
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as="div">
                  {" "}
                  <Link to="/sales-and-invoicing/create-invoice">
                    Performa Invoice
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="global-search-area">
              <MagnifyIcon />
              <Form.Control
                id="queryParams-q"
                className=""
                name="LastName"
                value={queryParams.LastName}
                onChange={(e) => handleSearchQueryChange(e)}
                placeholder="Search..."
              />
            </div>
            <Button variant="" className="bg-light-blue border-0 text-primary">
              <CachedIcon />
            </Button>
          </div>

         <div className="d-flex gap-3">
            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Type"
                name="category"
                isSearchable={true}
                key={data?.category}
               
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Last Created"
                name="category"
                isSearchable={true}
                key={data?.category}
              
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Filter By"
                name="category"
                isSearchable={true}
                key={data?.category}
               
                options={data?.category || []}
              />
            </Form.Group>
          </div> 
        </div>
      </header>

     
    </main> */
    <section className="cashbook-wrapped">
      <SalesByInvoice />
    </section>
  );
}
